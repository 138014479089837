/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const introduction = lazy(() => import('views/introduction/Introduction'));
const coingecko = lazy(() => import('views/api/CoingeckoAPI'));
const coincirculation = lazy(() => import('views/api/CoinCirculationAPI'));
const report = lazy(() => import('views/dat_research/Report'));
const twitter = lazy(() => import('views/dat_research/Twitter'));
const blog = lazy(() => import('views/dat_research/Blog'));
const intel = lazy(() => import('views/dat_research/Intel'));
const analytics = lazy(() => import('views/analytics/Analytics'));
const bvm = lazy(() => import('views/analytics/BinanceVolumeMarketcap'));
// const mma = lazy(() => import('views/analytics/MarketMakingAnalysis'));
const dune = lazy(() => import('views/dune/Dune'));
const platform = lazy(() => import('views/quant/Platform'));
const defitradingbot = lazy(() => import('views/quant/DefiTradingBot'));
const swap = lazy(() => import('views/swap/Swap'));

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/introduction`,
    },
    {
      path: `${appRoot}/introduction`,
      component: introduction,
      label: 'Home',
      icon: 'ablLogo',
    },
    // {
    //   path: `${appRoot}/api`,
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/api`,
    //   label: 'API',
    //   icon: 'link',
    //   subs: [
    //     { path: '/coingecko', label: 'Coin Gecko', component: coingecko },
    //     { path: '/coincirculation', label: 'Coin Circulation', component: coincirculation },
    //   ],
    // },
    {
      path: `${appRoot}/dat_research`,
      exact: true,
      redirect: true,
      to: `${appRoot}/dat_research`,
      label: 'DAT Research',
      icon: 'search',
      subs: [
        { path: '/blog', label: 'Research Blog', component: blog },
        { path: '/intel', label: 'Intel', component: intel },
        { path: '/twitter', label: 'Twitter', component: twitter },
        { path: '/report', label: 'Token Research Summary', component: report },
      ],
    },
    {
      path: `${appRoot}/dune`,
      component: dune,
      label: 'Dune Analytics',
      icon: 'chart-2',
    },
    // {
    //   path: `${appRoot}/analytics`,
    //   component: analytics,
    //   label: 'ABL Analytics',
    //   icon: 'chart-2',
    // },
    {
      path: `${appRoot}/analytics`,
      exact: true,
      redirect: true,
      to: `${appRoot}/analytics`,
      label: 'ABL Analytics',
      icon: 'chart-2',
      subs: [
        { path: '/analytics', label: 'Analytics Dashboard', component: analytics },
        { path: '/bvm', label: 'Binance Vol. Marketcap', component: bvm },
      ],
    },
    {
      path: `${appRoot}/quant`,
      exact: true,
      redirect: true,
      to: `${appRoot}/quant`,
      label: 'Quant Trading',
      icon: 'money',
      subs: [
        { path: '/platform', label: 'Trading Platform', component: platform },
        { path: '/defi_trading_bot', label: 'DeFi Trading Bot', component: defitradingbot },
      ],
    },
    {
      path: `${appRoot}/swap`,
      component: swap,
      label: 'ABL Swap (Under Dev.)',
      icon: 'dollar',
    },
    {
      path: `${appRoot}/auditbot`,
      component: introduction,
      label: 'Audit Bot (Under Dev.)',
      icon: 'toy',
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
